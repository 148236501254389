import Head from 'next/head';

import { Login } from '@templates';

import { useWindowSize } from '@hooks';

const LoginPage = () => {
  const { height } = useWindowSize();

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" key="robots" />
      </Head>
      <div style={{ minHeight: height }}>
        <Login />
      </div>
    </>
  );
};

export default LoginPage;
